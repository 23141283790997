import React from 'react';
import './About.css'; // Add custom CSS file for styling
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="container">
      <div className="header-about-us">
        <Link to="/">
          <img
            src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/lifewink_logo.png"
            className="lifewink_logo"
            alt="LifeWink Logo"
          />
        </Link>
      </div>
      <div className="content">
        <h1>About us</h1>
        <p>
          Over two decades ago my mom and dad passed away and during my lifetime
          I have lost many of my cherished family and friends. There is not a
          day that goes by that I don’t think about my mom and dad and the loved
          ones I have lost. To love and be loved is truly life’s greatest gift.
        </p>
        <p>
          Those who we love and those who love us have an overwhelming impact on
          our lives, and when they pass it is truly overwhelming... Our loved
          ones are here one day, and then they are gone.
        </p>
        <p>
          With this in mind LifeWink was created to enable our Members to
          connect again with those who they care most about by creating and
          scheduling future deliveries of their messages and stories by creating
          timeless private LifeWinks.
        </p>
        <p>
          It is our hope that our Members and their Recipients throughout our
          world will be inspired to have new conversations, behaviors, and
          experiences as a result of LifeWink.
        </p>
        <p>
          LifeWink is guided by our principles of love, integrity, innovation,
          and excellence, is Member and Recipient centric, and is genuinely
          driven to earn our Members and Recipients trust. LifeWink’s team is
          kind and brilliant and is dedicated to providing a truly meaningful
          Member and Recipient experience... while providing absolute privacy,
          security, and satisfaction.
        </p>
        <p>
          Needless to say, I wish I could be receiving timeless private
          LifeWinks today from those who I love and have lost... hoping LifeWink
          earns your trust and exceeds all your expectations.
        </p>
        <p>
          Jim Baumann
          <br />
          Founder & CEO
          <br />
          <a href="mailto:jbaumann@lifewink.com">jbaumann@lifewink.com</a>
        </p>

        <h1>Our Promise to Our Members and Recipients</h1>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by being honest and
          reliable.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by being respectful of
          every person.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by being Member and
          Recipient centric, being accessible, and by listening to learn and
          grow.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by never sharing or
          selling personal information.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by using security best
          practices to keep all personal data safe.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by living our guiding
          principles of love, integrity, innovation, and excellence.
        </p>
        <p className="bottom-paragraph">
          We will earn our Members and Recipients trust by securely saving and
          storing our Members' timeless private LifeWinks, and precisely and
          assuredly delivering them to their Recipients.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
